<template>
  <div class="tree">
    <el-tree
      :data="data"
      :current-node-key="-1"
      :default-expanded-keys="[-1]"
      :props="defaultProps"
      :highlight-current="false"
      node-key="id"
      accordion
      @node-click="handleNodeClick"
      ref="tree"
    >
      <span slot-scope="{ node }" class="custom-tree-node">
        <span
          style="padding-left: 4px"
          class="el-tree-label"
          :title="node.data.name.length > 5 ? node.data.name : ''"
          v-text="showTreeNodeName(node.data.name)"
        ></span>

        <span v-if="node.data.name == '本地监测'" @click="add($event, node)"
          ><i class="el-icon-circle-plus-outline"></i
        ></span>

        <span
          v-if="node.data.belong != null"
          class="el-tree-righticon el-icon-more"
        >
          <ul>
            <li @click="revise($event, node)">修改方案</li>
            <li @click="add($event, node)">添加</li>
            <li @click="remove($event, node.data.id)">删除</li>
          </ul>
        </span>
      </span>
    </el-tree>
    <div class="revise" v-show="showRevise">
      <div class="mask" @click="close"></div>
      <div class="revise-content">
        <div class="header">
          <span>专题设置</span>
          <button @click="close">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div class="container">
          <div class="writebox">
            <div class="inputbox">
              <input
                type="text"
                placeholder="请输入专题名称"
                v-model="nodeName"
              />
            </div>
          </div>

          <div class="tabList" v-if="!hasChilds">
            <div
              class="tab"
              v-for="item in tabList"
              :key="item.id"
              :class="{ selected: selectId == item.id }"
              v-text="item.tabName"
              @click="setCheck(item.id)"
            ></div>
          </div>
          <div class="setKeyword" v-show="1 == selectId">
            <div v-if="!hasChilds" class="writebox">
              <span>监测关键词设置：</span>
              <div class="textarea">
                <el-switch
                  v-model="slage"
                  :onchange="processNodeTags(searchRule)"
                  active-text="特殊语种"
                  inactive-text="普通语种"
                  v-if="false"
                >
                </el-switch>
                <textarea
                  v-if="!slage"
                  rows="3"
                  v-model="searchRule"
                ></textarea>

                <div v-if="slage">
                  <el-tag
                    size="small"
                    :key="tag"
                    style="line-height: 22px;"
                    v-for="tag in dynamicTags"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                </div>
              </div>
              <div class="desc">
                <i class="el-icon-warning-outline"></i>
                关键词之间用 “空格” 表示，是或的关系，如A B C…… 关键词之间用 “+”
                号表示，是与的关系，如：a+b a+c…… 关键词配置举例：A B C a+b
                a+c……
              </div>
            </div>
            <div v-if="!hasChilds" class="writebox">
              <span>排除关键词设置：</span>
              <div class="textarea">
                <textarea rows="3" v-model="filterRule"></textarea>
              </div>
            </div>
          </div>
          <div class="earlyWarning" v-show="2 == selectId">
            <div class="setModule">
              <div class="title">
                <b><span></span> 预警词设置</b>
              </div>
              <div class="content">
                <div style="margin-bottom: 15px">
                  <span>预警词：</span>
                  <textarea v-model="keyword"></textarea>
                </div>
                <div>
                  <span>排除词：</span>
                  <textarea v-model="filterKeyWord"></textarea>
                </div>
              </div>
            </div>
            <div class="setModule">
              <div class="title">
                <b><span></span> 预警条件设置</b>
              </div>
              <div class="content">
                <div class="select">
                  <span>匹配方式：</span>
                  <input
                    type="radio"
                    name="matchrule"
                    value="title,content"
                    v-model="alarmField"
                  />全部
                  <input
                    type="radio"
                    name="matchrule"
                    value="title"
                    v-model="alarmField"
                  />标题
                  <input
                    type="radio"
                    name="matchrule"
                    value="content"
                    v-model="alarmField"
                  />内容
                </div>
                <div class="select">
                  <span>预警去重：</span>
                  <el-switch v-model="alarmDouble"> </el-switch>
                </div>
              </div>
            </div>

            <div class="setModule">
              <div class="title">
                <b><span></span> 预警开关</b>
              </div>
              <div class="select">
                <span>状态：</span>
                <el-switch v-model="alarmRuleSwitch"> </el-switch>
              </div>
            </div>

            <div class="setModule">
              <div class="title">
                <b><span></span> 铃声设置</b>
              </div>
              <div class="select">
                <span>响铃时间：</span>
                <el-radio-group v-model="alarmTime">
                  <el-radio :label="0">无响铃</el-radio>
                  <el-radio :label="6000">响铃三次</el-radio>
                  <el-radio :label="-1">持续响铃</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="btns">
            <button class="save" @click="save">保存</button>
            <button class="reset" @click="close">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconImg from "@/assets/logo.png";
import { alarmFlagEnum } from "../utils/constants";
import { duplicateWords } from "@/utils/api.js";

export default {
  name: "HelloWorld",
  components: {},
  data() {
    return {
      iconImg,
      //   icon: require("@/assets/logo.png"),
      data: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      num: 1,
      tabList: [
        {
          id: 1,
          tabName: "关键词设置"
        },
        {
          id: 2,
          tabName: "预警设置"
        }
      ],
      dynamicTags: ["标签一", "标签二", "标签三"],
      slage: false,
      selectId: 1,
      alarmDouble: false,
      showRevise: false,
      nodeName: "",
      searchRule: "",
      filterRule: "",
      alarmField: "",
      filterKeyWord: "",
      keyword: "",
      reviseNodeId: null,
      reviseFlag: "",
      hasChilds: false,
      alarmRuleSwitch: false, //预警开关
      alarmTime: 6000
    };
  },
  methods: {
    async handleNodeClick(data) {
      this.hasChilds = "children" in data;
      this.$emit("clickNode", data);
      let alarmFlag = localStorage.getItem(`alarmFlag${data.id}`);
      let timeSort = localStorage.getItem(`timeSort${data.id}`);
      let minganlocal = localStorage.getItem(`mingan_${data.id}`);
      let startTime = new Date();
      startTime.setTime(new Date() - 3600 * 1000 * 24 * 3);
      // let startTime = new Date(new Date().setHours(0, 0, 0, 0));

      let searchRuleArr = new Array();
      let filterRuleArr = new Array();
      this.getFieldArray(data, "searchRule", searchRuleArr);
      this.getFieldArray(data, "filterRule", filterRuleArr);

      let selectTypeStr = localStorage.getItem(`selectType${data.id}`);

      let requestData = {
        rule: searchRuleArr.join(","),
        filterRule: filterRuleArr.join(","),
        startTime: startTime.format(),
        endTime: new Date().format(),
        duplicate: alarmFlag ? alarmFlagEnum[alarmFlag] : false,
        category: selectTypeStr ? selectTypeStr : "",
        translate: false,
        timeField: timeSort ? timeSort : "time",
        risis: minganlocal == "true" ? "2" : ""
      };
      this.$emit("changeParams", requestData);
    },

    getFieldArray(data, field, fieldArray) {
      if ("children" in data) {
        data.children.forEach(ele => {
          this.getFieldArray(ele, field, fieldArray);
        });
      } else {
        if (data[field]) {
          fieldArray.push(data[field]);
        }
      }
    },

    processNodeTags(searchRule) {
      if (this.slage) {
        this.dynamicTags = this.extractData(searchRule);
      }
    },
    extractData(str) {
      // 匹配括号内的内容
      const matches = str.match(/\(([^)]+)\)/g);
      // 去掉括号并返回结果
      return matches ? matches.map(item => item.slice(1, -1)) : null;
    },

    showTreeNodeName(nodeName) {
      if (nodeName.length > 5) {
        nodeName = nodeName.substring(0, 5) + "...";
      }
      return nodeName;
    },

    convertToTreeData(data) {
      let treeData = new Array();
      for (var node of data) {
        if (node.parent == null) {
          this.findChildNode(node, data);
          treeData.push(node);
        }
      }
      return treeData;
    },

    findChildNode(obj, nodeList) {
      for (var node of nodeList) {
        if (node.parent != null && obj.id == node.parent) {
          this.findChildNode(node, nodeList);
          if (!obj.children) {
            obj.children = new Array();
          }
          obj.children.push(node);
        }
      }
    },

    setCheck(id) {
      this.selectId = id;
    },
    async save() {
      let requestData = {};
      if (this.selectId === 1) {
        requestData = {
          name: this.nodeName,
          searchRule: this.searchRule,
          filterRule: this.filterRule
        };
        if (this.reviseFlag === "add" && this.reviseNodeId != -1) {
          requestData = {
            "parent.id": this.reviseNodeId,
            ...requestData
          };
          console.log(requestData);
        } else if (this.reviseFlag === "revise") {
          requestData = {
            id: this.reviseNodeId,
            ...requestData
          };
        }
        requestData.filterRule = duplicateWords(requestData.filterRule);
        await this.$request.post("/api/doc/ruleNode", requestData);
      } else {
        localStorage.setItem("alarmTime", this.alarmTime);
        requestData = {
          "ruleNode.id": this.reviseNodeId,
          alarmDouble: this.alarmDouble,
          alarmField: this.alarmField,
          filterKeyWord: this.filterKeyWord,
          keyword: this.keyword,
          state: this.alarmRuleSwitch
        };
        requestData.keyword = duplicateWords(requestData.keyword);
        requestData.filterKeyWord = duplicateWords(requestData.filterKeyWord);
        await this.$request.post("/api/doc/ruleNode/alarmRule", requestData);
      }
      this.resetTreeNode();
      this.showRevise = false;
      this.$message.success("保存成功");
    },
    close() {
      this.clearDatas();
      this.showRevise = false;
    },
    clearDatas() {
      this.selectId = 1;
      this.nodeName = "";
      this.searchRule = "";
      this.filterRule = "";
      this.alarmDouble = false;
      this.alarmField = "";
      this.filterKeyWord = "";
      this.keyword = "";
    },
    revise(e, node) {
      this.hasChilds = node.childNodes.length > 0;
      e.stopPropagation();
      this.reviseNodeId = node.data.id;
      this.nodeName = node.data.name;
      this.searchRule = node.data.searchRule;
      this.filterRule = node.data.filterRule;
      this.showRevise = true;
      this.reviseFlag = "revise";

      //请求存在的预警条件
      if (this.reviseNodeId) {
        if (localStorage.getItem("alarmTime")) {
          this.alarmTime = parseInt(localStorage.getItem("alarmTime"));
        } else {
          this.alarmTime = 6000;
        }

        this.$request
          .get("/api/doc/ruleNode/alarmRule/" + this.reviseNodeId)
          .then(res => {
            let alarmRule = res.data;
            if (alarmRule) {
              //存在预警条件 回显数据
              this.alarmDouble = alarmRule.alarmDouble;
              (this.alarmField = alarmRule.alarmField),
                (this.filterKeyWord = alarmRule.filterKeyWord),
                (this.keyword = alarmRule.keyword);
              this.alarmRuleSwitch = alarmRule.state;
            } else {
              this.alarmDouble = false;
              (this.alarmField = "title,content"),
                (this.filterKeyWord = ""),
                (this.keyword = "");
              this.alarmRuleSwitch = false;
            }
          });
      }
    },
    remove(e, id) {
      this.$confirm("确认删除该节点?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        e.stopPropagation();
        this.$request.delete(`/api/doc/ruleNode/${id}`).then(() => {
          this.$message.success("删除成功!");
          this.resetTreeNode();
        });
      });
    },

    resetTreeNode() {
      this.data = new Array();
      this.$request.get("/api/doc/ruleNode").then(res => {
        let localNode = this.convertToTreeData(res.data);
        let localHeader = { name: "本地监测", children: localNode, id: -1 };
        this.data.push(localHeader);
        this.$request.get("/api/sys/resource/specialDocNode").then(res => {
          let specNode = this.convertToTreeData(res.data);
          let specHeader = { name: "专项监测", children: specNode };
          this.data.push(specHeader);
          let node = this.$refs.tree.getNode(-1);
          this.handleNodeClick(node.data);
        });
      });
    },
    add(e, node) {
      this.nodeName = "";
      this.searchRule = "";
      this.filterRule = "";
      console.log(node);
      this.hasChilds = false;
      console.log(this.hasChilds);
      e.stopPropagation();
      this.showRevise = true;

      if (node.data.id) {
        console.log("id---", node.data.id);
        this.reviseNodeId = node.data.id;
        this.reviseFlag = "add";
      }
    }
  },
  created() {
    this.resetTreeNode();
  }
};
</script>
<style>
body {
  overflow: hidden;
}
.slide-enter-to,
.slide-leave {
  transform: translateX(0);
  opacity: 1;
}
.slide-leave-to,
.slide-enter {
  transform: translateX(600px);
  opacity: 0;
}
.tree {
  width: 100%;
}

.tree .el-tree {
  height: 100%;
  background: none;
}
.tree .el-tree-node__content {
  height: 48px;
  line-height: 48px;
  margin-top: 10px;
  background: #222e44;
  border: 1px solid #2f4b77;
}

.tree .el-tree-node__children .el-tree-node__content {
  height: 40px;
  line-height: 40px;
  border: none;
  margin: 0;
  font-size: 14px;
}
.tree .el-tree-node__children .el-tree-node__content .custom-tree-node {
  font-weight: 500;
}
.el-tree-node.is-current > .el-tree-node__content {
  background: #f2f3f7;
}
.el-tree-node__content:hover {
  background: #f2f3f7;
}
.tree .el-tree-node__content:hover .custom-tree-node {
  color: #5090f1;
}
.el-tree-node.is-current > .el-tree-node__content .custom-tree-node {
  color: #5090f1;
}
.el-tree-node__content:hover .custom-tree-node .el-tree-righticon {
  display: inline-block;
}
.tree .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tree {
  .addNode {
    padding-left: 15px;
    height: 45px;
    line-height: 45px;
    text-align: left;
    color: #ddd;
    background: #222e44;
    border-bottom: 1px solid #2f4b77;
    span {
      margin-right: 10px;
      font-weight: 700;
    }
    .addnode-icon {
      background: #177fc8;
      display: inline-block;
      // font-size: 20px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
    }
  }
  .revise {
    .mask {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 100;
    }
    .revise-content {
      z-index: 101;
      width: 600px;
      height: 100%;
      border: 2px solid #f2f3f7;
      box-sizing: border-box;
      text-align: left;
      position: absolute;
      background: #fff;
      right: 0;
      top: 0;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 10px;
        color: #72767b;
        span {
          font-size: 16px;
          font-weight: 600;
        }
        button {
          border: none;
          outline: none;
          background: #fff;
          font-size: 24px;
        }
      }
      .container {
        padding: 0 20px;
        .tabList {
          display: flex;
          align-items: center;
          border: 1px solid #5090f1;
          .tab {
            flex: 1;
            text-align: center;
            color: #5090f1;
            line-height: 30px;
            &.selected {
              color: #fff;
              background: #5090f1;
            }
          }
        }
        .writebox {
          margin-bottom: 20px;
          span {
            line-height: 40px;
          }
          .inputbox {
            width: 100%;
            height: 32px;
            line-height: 30px;
            box-sizing: border-box;
            padding: 0 50px 0 20px;
            border: 1px solid #808080;
            input {
              border: none;
              outline: none;
              font-size: 14px;
              width: 100%;
            }
          }
          .textarea {
            // height: 50px;
            textarea {
              width: 100%;
              height: 100%;
              line-height: 20px;
              resize: vertical;
              font-family: inherit;
            }
          }
        }
        .btns {
          width: 100%;
          height: 50px;
          line-height: 50px;
          text-align: right;
          button {
            color: #fff;
            margin-right: 20px;
            border: none;
            outline: none;
            padding: 7px 20px;
            font-size: 14px;
            border-radius: 2px;
            &.save {
              background: #5090f1;
            }
            &.reset {
              background: #858da6;
            }
          }
        }
        .earlyWarning {
          .setModule {
            margin-bottom: 20px;
            .title {
              b {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                span {
                  width: 4px;
                  height: 20px;
                  background: #5090f1;
                  margin-right: 5px;
                }
              }
            }
            .content {
              padding-left: 25px;
              div {
                display: flex;
                align-items: flex-start;
                textarea {
                  flex: 1;
                  min-height: 30px;
                }
                &.select {
                  align-items: center;
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .custom-tree-node {
    width: 100%;
    color: #ddd;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    // align-content: space-between;
    justify-content: space-between;
    padding-right: 15px;
  }
  .el-tree-righticon {
    font-size: 20px;
    margin-right: 0px;
    position: relative;
    display: none;
    ul {
      // z-index: 99999;
      display: none;
      position: absolute;
      right: -11px;
      top: 20px;
      background: #f2f3f7;
      box-shadow: 0 0 6px 0 rgb(110 110 110 / 40%);
      color: #555;
      li {
        padding: 5px 15px;
        line-height: 20px;
        font-size: 14px;
        color: #222;
        &:hover {
          background: #fff;
          color: #5090f1;
        }
      }
    }
    &:hover ul {
      display: block;
    }
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.desc {
  font-size: 13px;
  color: #808080;
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
